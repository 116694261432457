<template>
    <div>
        <panel noButton="true" variant="default">
            <template slot="header">
                <h4 class="panel-title tw-uppercase tw-font-bold">
                    {{ $t('positionHierarchy.pageTitle') }}
                </h4>
                <a
                    href="#"
                    class="btn btn-xs btn-success"
                    @click.prevent="fetchData"
                    v-tooltip="$t('refresh')"
                >
                    <Icon type="md-refresh" size="20" />
                </a>
            </template>
            <div class="demo-spin-article">
                <div v-if="!treeData" class="text-center">
                    No Data
                </div>
                <div v-else>
                    <ts-org-tree
                        :treeData="treeData"
                        :append="false"
                        :edit="false"
                        :remove="false"
                        :images="true"
                    ></ts-org-tree>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </panel>
    </div>
</template>

<script>
export default {
    name: 'positionHierarchy',
    data () {
        return {
            loading: false,
            treeData: null
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('humanResource/positionHierarchy/fetch', {
                    ...attributes
                })
                .then(response => {
                    if (response.data.length > 0) {
                        this.treeData = response.data
                    }
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'POSITION HIERARCHY',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
